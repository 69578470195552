import { DrawerItem } from "@progress/kendo-angular-layout";

export interface RgridDrawerItem extends DrawerItem { 
  path?: string;
  title?: string;
  roles?: Array<string>;
}
export const items: Array<RgridDrawerItem> = [
  { id: 0, parentId: undefined, text: 'Customer', title: 'Customer', iconClass: 'fa-duotone fa-user-tie', path: undefined, roles: ['user', 'admin'], selected: false },
  { id: 2, parentId: 0, text: 'Analytics', title: 'Analytics', iconClass: 'fa-duotone fa-chart-line', path: '/dashboard', roles: ['user', 'admin'], selected: false },
  { id: 3, parentId: 0, text: 'Menus', title: 'Menus', iconClass: 'fa-duotone fa-bars', path: '/menus', roles: ['user', 'admin'], selected: false },
  { id: 4, parentId: 0, text: 'Account', title: 'Account', iconClass: 'fa-regular fa-user-pen', path: '/account', roles: ['user', 'admin'], selected: false },
  // { id: 5, parentId: 0, text: 'Voice', title: 'Voice', icon: 'fa-duotone fa-inbox-full', path: '/voice' },
  { id: 6, parentId: 0, text: 'Availability', title: 'Availability', iconClass: 'fa-regular fa-clock', path: '/availability', roles: ['user', 'admin'], selected: false },
  { id: 7, parentId: 0, text: 'Invoices', title: 'Invoices', iconClass: 'fa-light fa-file-invoice', path: '/invoices', roles: ['user', 'admin'], selected: false },
  { id: 1, parentId: undefined, text: 'Admin', title: 'Admin', iconClass: 'fa-duotone fa-user-gear', path: undefined, roles: ['admin'], selected: false },
  { id: 8, parentId: 1, text: 'Users', title: 'Users', iconClass: 'fa-light fa-users', path: '/admin/users', roles: ['user', 'admin'], selected: false },
  { id: 9, parentId: 0, text: 'Numbers', title: 'Numbers', iconClass: 'fa-regular fa-phone', path: '/numbers', roles: ['user', 'admin'], selected: false },
  { id: 10, parentId: 0, text: 'Voicemails', title: 'Voicemails', iconClass: 'fa-thin fa-voicemail', path: '/voicemails', roles: ['user', 'admin'], selected: false },
  { id: 11, parentId: 0, text: 'Extensions', title: 'Extensions', iconClass: 'fa-thin fa-phone-office', path: '/extensions', roles: ['user', 'admin'], selected: false },
  { id: 12, parentId: 0, text: 'Speech AI', title: 'Speech AI', iconClass: 'fa-duotone fa-microphone', path: '/speech', roles: ['user', 'admin'], selected: false },
  { id: 13, parentId: 0, text: 'Messages', title: 'Messages', iconClass: 'fa-solid fa-messages', path: '/sms', roles:['admin'], selected: false },
  { id: 14, parentId: 0, text: 'VoIP', title: 'VoIP', iconClass: 'fa-solid fa-phone', path: 'https://voip.rgrid.io', roles: ['admin'], selected: false },
  { id: 15, parentId: 0, text: 'E-Fax', title: 'eFaxes', iconClass: 'fa-solid fa-fax', path: 'https://efax.rgrid.io', roles: ['admin'], selected: false },

];