import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerItem, DrawerItemExpandedFn, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { AuthService, User } from '@auth0/auth0-angular';
import { items } from './items';
import { SessionService } from 'src/app/core/services/session.service';
import { Location } from '@angular/common';
import { EventsService } from 'src/app/core/services/events.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SVGIcon, menuIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-portal-layout',
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
})
export class PortalLayoutComponent {
  public expanded = true;
  public itemIndex!: number;

  pageTitle!: any;

  user: User | null | undefined;

  constructor(
    private router: Router,
    public auth: AuthService,
    public session: SessionService,
    private location: Location,
    private events: EventsService,
    private storage: LocalStorageService
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectDrawerItemByPath(event.urlAfterRedirects);
      }
    });
  }

  public menuSvg: SVGIcon = menuIcon;
  public selected = "Inbox";
  public expandedIndices = [0, 1];
  public items: Array<DrawerItem> = items;

  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  public onSelect(ev: DrawerSelectEvent): void {
    this.events.navigationClick(ev.item);
    this.selected = ev.item.text;
    const current = ev.item.id;

    if (this.expandedIndices.indexOf(current) >= 0) {
      this.expandedIndices = this.expandedIndices
        .filter((id) => id !== current);
    } else {
      this.expandedIndices.push(current);
    }

    if (ev.item.path) {
      if(!ev.item.path.startsWith('http')) {
        this.router.navigate([ev.item.path]);
      } else {
        window.open(ev.item.path, '_blank');
      }
    }
  }

  ngOnInit(): void {
    this.selectDrawerItemByPath(this.location.path());
    this.items
      .forEach((x: any) => {
        x.selected = false
        if (x.path && x.path.toLowerCase() == this.location.path().toLowerCase()) {
          x.selected = true;
        }
      });

    this.auth.user$.subscribe((x) => {
      this.user = x;
    });

    this.items = items.filter((x) => {
      return x.roles?.some(role => this.session?.user?.roles?.includes(role));
    });    
  }

  private selectDrawerItemByPath(path: string): void {
    const updatedItems = [...this.items];
    updatedItems.forEach((x: any) => {
      x.selected = false;
      if (x.path && x.path.toLowerCase() === path.toLowerCase()) {
        x.selected = true;
      }
    });
    this.items = updatedItems;
  }

  async logout(): Promise<void> {
    localStorage.removeItem('subscription');
    this.storage.clearLocalStorage();
    await this.auth.logout({
      openUrl(url) {
        window.location.replace(url);
      }
    });
  }
}
